<script setup lang="ts">
defineOptions({
  name: 'ContentPage',
});

const emit = defineEmits(['page-not-found']);

const { $storyblokLivePreview } = useNuxtApp();
const { initTransition } = useMopPageTransitionClient();
const route = useRoute();

const { handleHeadForCmsPage } = useMopSeo();

const classNameRef = ref(['']);
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef } = useMopCms();

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await getCmsStory(`/${(route.params.path as string).replace('theme-company/', '').replace('theme-company', '')}`);
  if (cmsStoryModelRef.value.hasErrorNotFound()) {
    emit('page-not-found');
    return;
  }
  trackPageView('Company');
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['content-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopCmsContentElementsSlot id="content-page" :elements="cmsStoryModelRef.getContentElements()" />
    </div>
  </div>
</template>
